<template>
  <div class="h-screen flex overflow-y-auto bg-white">
    <div class="flex flex-col w-0 flex-1">
      <home-header breadcrumb />
      <main class="flex-1 relative focus:outline-none" tabindex="0">
        <div class="py-6">
          <div class="mx-auto px-4 sm:px-6 md:px-8 flex flex-col space-y-8">
            <div id="vue-root" class="text-black">
              <!--  main -->

              <p class="font-semibold text-xl mb-3" v-if="role">
                Role Name:
                <input
                  type="text"
                  class="form-control rounded-md mb-3 border-gray-300 mt-2"
                  v-model="role.name"
                  placeholder="Role Name"
                  required
                />
              </p>
              <div class="overflow-x-auto w-full">
                <datatable
                  :columns="columns"
                  :data="rows"
                  :per-page="10"
                  v-if="rows"
                >
                  <!-- eslint-disable-next-line -->
                  <template slot-scope="{ row, index }" v-if="role">
                    <tr v-if="index == 0">
                      <th>
                        MODULE
                      </th>
                      <th>
                        <input
                          type="checkbox"
                          :checked="add_flag"
                          @click="setPermissionAll('add')"
                          class="mr-1 mb-1"
                        />
                        ADD
                      </th>
                      <th>
                        <input
                          type="checkbox"
                          :checked="get_flag"
                          @click="setPermissionAll('get')"
                          class="mr-1 mb-1"
                        />READ
                      </th>
                      <th>
                        <input
                          type="checkbox"
                          :checked="update_flag"
                          @click="setPermissionAll('update')"
                          class="mr-1 mb-1"
                        />UPDATE
                      </th>
                      <th>
                        <input
                          type="checkbox"
                          :checked="delete_flag"
                          @click="setPermissionAll('delete')"
                          class="mr-1 mb-1"
                        />DELETE
                      </th>
                    </tr>
                    <tr>
                      <td>{{ getModuleName(row.module) }}</td>
                      <td>
                        <input
                          type="checkbox"
                          :checked="row.add == 1"
                          :disabled="row.add == undefined"
                          class="disabled:bg-gray-300"
                          @click="setPermission(row.module, 'add')"
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          :checked="row.get == 1"
                          :disabled="row.get == undefined"
                          class="disabled:bg-gray-300"
                          @click="setPermission(row.module, 'get')"
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          :checked="row.update == 1"
                          :disabled="row.update == undefined"
                          class="disabled:bg-gray-300"
                          @click="setPermission(row.module, 'update')"
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          :checked="row.delete == 1"
                          :disabled="row.delete == undefined"
                          class="disabled:bg-gray-300"
                          @click="setPermission(row.module, 'delete')"
                        />
                      </td>
                    </tr>
                  </template>
                  <!-- eslint-disable-next-line -->
                </datatable>
              </div>
              <div class="flex justify-end" v-if="role">
                <button
                  type="submit"
                  class="text-white text-tiny sm:text-base bg-red-600 hover:bg-gray-400 font-bold px-3 rounded-sm inline-flex items-center mr-2 h-10"
                  @click="deleteRolePermission"
                  v-if="getPermission('Permissions', 'delete') == 1"
                >
                  Delete Role
                </button>
                <button
                  type="submit"
                  class="text-white text-tiny sm:text-base bg-green-500 hover:bg-gray-400 font-bold px-3 rounded-sm inline-flex items-center mr-2 h-10"
                  @click="updateRolePermission"
                  v-if="getPermission('Permissions', 'update') == 1"
                >
                  Update Role
                </button>
              </div>
              <!-- End Main -->
            </div>
            <!-- /End replace -->
          </div>
        </div>
      </main>
      <home-footer />
    </div>
  </div>
</template>

<script>
import { HomeHeader } from "../../layouts";
import { HomeFooter } from "../../components";
import api from "@/api/apiServices";
import { permissionsModuleName } from "../../utils/permission.js";

export default {
  name: "EditPermission",
  components: {
    HomeHeader,
    HomeFooter,
  },
  async mounted() {
    if (this.role) {
      this.getRolePermission(this.role.roleId);
    } else {
      this.$router.push({ name: "Permission" });
    }
  },
  data() {
    return {
      role: this.$route.params.role,
      page: 1,
      columns: [],
      rows: [],
      permission: this.$store.getters["security/currentPermission"],
      add_flag: 1,
      get_flag: 1,
      update_flag: 1,
      delete_flag: 1,
    };
  },
  methods: {
    getModuleName(moduleName) {
      if (permissionsModuleName[moduleName]) {
        return permissionsModuleName[moduleName];
      } else {
        return moduleName;
      }
    },
    async getRolePermission(role) {
      api.getRolePermission(role).then((res) => {
        this.rows = res.data;
        for (let i = 0; i < this.rows.length; i++) {
          let element = this.rows[i];
          if (element.add == 0) {
            this.add_flag = 0;
          }
          if (element.get == 0) {
            this.get_flag = 0;
          }
          if (element.update == 0) {
            this.update_flag = 0;
          }
          if (element.delete == 0) {
            this.delete_flag = 0;
          }
        }
      });
    },
    async setPermission(module, type) {
      for (let i = 0; i < this.rows.length; i++) {
        let element = this.rows[i];
        if (element.module == module) {
          element[type] = element[type] == 1 ? 0 : 1;
          break;
        }
      }
      this[type + "_flag"] = this.getAllPermissionTypeStatus(type);
    },
    getAllPermissionTypeStatus(type) {
      var flag = 1;
      for (let i = 0; i < this.rows.length; i++) {
        let element = this.rows[i];
        if (element[type] == 0) {
          flag = 0;
          break;
        }
      }
      return flag;
    },
    async setPermissionAll(type) {
      let flag = this[type + "_flag"] == 0 ? 1 : 0;
      for (let i = 0; i < this.rows.length; i++) {
        let element = this.rows[i];
        element[type] = flag;
      }
      this[type + "_flag"] = flag;
    },
    async updateRolePermission() {
      let params = {
        roleId: this.role.roleId,
        roleName: this.role.name.trim(),
        permissions: this.rows,
      };
      api
        .updateRolePermission(params)
        .then((res) => {
          var role = this.$store.getters["security/currentRole"];
          if (role.roleId == params.roleId) {
            var roleObj = {
              role: { roleId: params.roleId, roleName: params.roleName },
              permission: params.permissions,
            };
            this.$store.commit("security/setPermission", roleObj);
          }
          this.$fire({
            title: "Permissions updated",
            text: res.message,
            type: "success",
            timer: 3000,
          });
        })
        .catch((error) => {
          this.$fire({
            title: "Failed to update permissions",
            text: error.message,
            type: "error",
          });
        });
    },
    async deleteRolePermission() {
      this.$confirm(
        `Are you sure want to delete this role "${this.role.name}" ? `
      )
        .then((res) => {
          if (res == true) {
            api
              .deleteRolePermission(this.role.roleId)
              .then((res) => {
                this.$fire({
                  title: "Permissions updated",
                  text: res.message,
                  type: "success",
                  timer: 3000,
                }).then(() => this.$router.back());
              })
              .catch((error) => {
                this.$fire({
                  title: "Failed to update permissions",
                  text: error.message,
                  type: "error",
                });
              });
          }
        })
        .catch(() => {});
    },
    getPermission(module, type) {
      for (let i = 0; i < this.permission.length; i++) {
        const element = this.permission[i];
        if (module == element.module) {
          return element[type];
        }
      }
    },
  },
};
</script>

<style lang="css">
th {
  background-color: #3a4750;
  color: white;
  border: 1px solid;
  border-color: white;
}
</style>
<style src="../../styles/table.css"></style>
