<template>
  <div class="h-screen flex overflow-y-auto bg-white">
    <div class="flex flex-col w-0 flex-1">
      <home-header breadcrumb />
      <main class="flex-1 relative focus:outline-none" tabindex="0">
        <div class="mx-auto py-2 px-4 sm:px-6 md:px-8 flex flex-col">
          <div id="vue-root" class="text-black">
            <!--  main -->
            <div class="flex flex-row justify-end">
              <router-link
                class="text-white bg-red-600 hover:bg-green-500 font-bold px-3 rounded-sm inline-flex items-center h-10"
                :to="{ path: 'add' }"
                v-if="getPermission('Permissions', 'add') == 1"
                append
              >
                <font-awesome-icon
                  :icon="['fas', 'id-card']"
                  size="lg"
                  class="mr-2"
                />
                New Role
              </router-link>
            </div>
            <datatable
              :columns="columns"
              :data="rows"
              :per-page="10"
              v-if="rows"
            >
              <!-- eslint-disable-next-line -->
              <template slot-scope="{ row, columns }">
                <tr>
                  <td>{{ row.name }}</td>
                  <td>
                    <div class="text-center text-red-500 font-bold">
                      <button
                        class="m-1"
                        @click="goToUpdateRolePermission(row)"
                        v-if="getPermission('Permissions', 'update') == 1"
                      >
                        <font-awesome-icon :icon="['fas', 'user-edit']" />
                      </button>
                      <button
                        class="m-1"
                        @click="deleteRolePermission(row.roleId, row.name)"
                        v-if="getPermission('Permissions', 'delete') == 1"
                      >
                        <font-awesome-icon :icon="['fas', 'trash-alt']" />
                      </button>
                    </div>
                  </td>
                </tr>
              </template>
            </datatable>
            <!-- End Main -->
          </div>
          <div class="text-black my-4 lg:my-6">
            <p class="font-bold text-base lg:text-xl">Statistics</p>
            <datatable :columns="statsColumn" :data="statsRow">
              <!-- eslint-disable-next-line -->
              <template slot-scope="{ row, columns }">
                <tr>
                  <td>{{ row.name }}</td>
                  <td>
                    {{ row.value }}
                  </td>
                </tr>
              </template>
            </datatable>
          </div>
          <!-- /End replace -->
        </div>
      </main>
      <home-footer />
    </div>
  </div>
</template>

<script>
import { HomeHeader } from "../../layouts";
import { HomeFooter } from "../../components";
import api from "@/api/apiServices";

export default {
  name: "Permission",
  components: {
    HomeFooter,
    HomeHeader,
  },
  async mounted() {
    if (this.getPermission("Permissions", "get") == 0) {
      this.$fire({
        title: "Access Error",
        text: "User does not have permissions to access the page",
        type: "error",
      });
      this.$router.push({ name: "Home" });
    } else {
      this.getRoleList();
      this.getStats();
    }
  },
  data() {
    return {
      members: [],
      page: 1,
      statsRow: [
        {
          name: "Total active users",
          value: 0,
        },
        {
          name: "Logged in users (past hour)",
          value: 0,
        },
        {
          name: "Daily site visits",
          value: 0,
        },
        {
          name: "Monthly site visits",
          value: 0,
        },
        {
          name: "Yearly site visits",
          value: 0,
        },
      ],
      statsColumn: [
        {
          label: "Stats",
          headerClass: "font-semibold border-2 h-10",
          interpolate: true,
        },
        {
          label: "Value",
          headerClass: "font-semibold border-2 h-10",
          interpolate: true,
        },
      ],
      columns: [
        {
          label: "Role",
          headerClass: "font-semibold border-2 h-10",
          interpolate: true,
        },
        {
          label: "Action",
          headerClass: "font-semibold border-2 h-10",
          sortable: false,
          interpolate: true,
        },
      ],
      rows: [],
      permission: this.$store.getters["security/currentPermission"],
    };
  },
  methods: {
    async getRoleList() {
      api.getRolePermission().then((res) => {
        this.rows = res.data;
      });
    },
    async goToUpdateRolePermission(role) {
      this.$router.push({
        name: `EditPermission`,
        params: { role: role },
      });
    },
    async getStats() {
      api.getUser().then((res) => {
        var members = res.data.Users;
        this.statsRow[0].value = this.getTotalActiveUsers(members);
        this.statsRow[1].value = this.getActiveUserHours(members);
        api.getVisit().then((res) => {
          var visits = {
            daily: res.data["daily"]["count"],
            monthly: res.data["monthly"]["count"],
            yearly: res.data["yearly"]["count"],
          };
          this.statsRow[2].value = visits["daily"];
          this.statsRow[3].value = visits["monthly"];
          this.statsRow[4].value = visits["yearly"];
        });
      });
    },
    getTotalActiveUsers(members) {
      var count = 0;
      members.forEach((row) => {
        if (row.UserStatus == "CONFIRMED") {
          count += 1;
        }
      });
      return count;
    },
    getActiveUserHours(members) {
      var count = 0;
      var now = new Date();
      var hour = 60 * 60 * 1000;
      members.forEach((row) => {
        var lastLogin = row.Attributes.filter(
          (item) => item.Name == "custom:lastLogin"
        );
        if (lastLogin.length > 0) {
          lastLogin = new Date(lastLogin[0]["Value"]);
          if (now - lastLogin < hour) count += 1;
        }
      });
      return count;
    },
    async deleteRolePermission(role, name) {
      this.$confirm(`Are you sure want to delete this role "${name}" ? `)
        .then((res) => {
          if (res == true) {
            api.checkRoleDelete(role).then((res) => {
              if (!res.data) {
                api
                  .deleteRolePermission(role)
                  .then((res) => {
                    this.getRoleList();
                    this.$fire({
                      title: "Role deleted",
                      text: res.message,
                      type: "success",
                      timer: 3000,
                    });
                    this.getRoleList();
                  })
                  .catch((error) => {
                    this.getRoleList();
                    this.$fire({
                      title: "Failed to delete role",
                      text: error.message,
                      type: "error",
                    });
                  });
              } else {
                this.$fire({
                  title: "Role deletion failed",
                  text: res.message,
                  type: "error",
                  timer: 3000,
                });
              }
            });
          }
        })
        .catch(() => {});
    },
    getPermission(module, type) {
      for (let i = 0; i < this.permission.length; i++) {
        const element = this.permission[i];
        if (module == element.module) {
          return element[type];
        }
      }
    },
  },
};
</script>

<style src="../../styles/table.css"></style>
<style lang="css">
th {
  background-color: #3a4750;
  color: white;
  border: 1px solid;
  border-color: white;
}
</style>
