<template>
  <div class="h-screen flex overflow-y-auto bg-white">
    <div class="flex flex-col w-0 flex-1">
      <home-header breadcrumb />
      <main class="flex-1 relative focus:outline-none" tabindex="0">
        <div class="py-6">
          <div class="mx-auto px-4 sm:px-6 md:px-8 flex flex-col space-y-8">
            <div id="vue-root" class="text-black">
              <!--  main -->
              <p>Please enter the role name:</p>
              <input
                type="text"
                class="form-control rounded-sm-md my-3 border-gray-300 w-full sm:w-3/12"
                v-model="roleName"
                placeholder="Role Name"
                @keydown="$event.stopImmediatePropagation()"
              />
              <div class="overflow-x-auto w-full">
                <datatable
                  :columns="columns"
                  :data="rows"
                  :per-page="10"
                  v-if="rows"
                >
                  <!-- eslint-disable-next-line -->
                  <template slot-scope="{ row, index }" v-if="role !== ''">
                    <tr v-if="index == 0">
                      <th>
                        MODULE
                      </th>
                      <th>
                        <input
                          type="checkbox"
                          :checked="add_flag"
                          @click="setPermissionAll('add')"
                          class="mr-1 mb-1"
                        />
                        ADD
                      </th>
                      <th>
                        <input
                          type="checkbox"
                          :checked="get_flag"
                          @click="setPermissionAll('get')"
                          class="mr-1 mb-1"
                        />READ
                      </th>
                      <th>
                        <input
                          type="checkbox"
                          :checked="update_flag"
                          @click="setPermissionAll('update')"
                          class="mr-1 mb-1"
                        />UPDATE
                      </th>
                      <th>
                        <input
                          type="checkbox"
                          :checked="delete_flag"
                          @click="setPermissionAll('delete')"
                          class="mr-1 mb-1"
                        />DELETE
                      </th>
                    </tr>
                    <tr>
                      <td>{{ getModuleName(row.module) }}</td>
                      <td>
                        <input
                          type="checkbox"
                          :checked="row.add == 1"
                          :disabled="row.add == undefined"
                          class="disabled:bg-gray-300"
                          @click="setPermission(row.module, 'add')"
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          :checked="row.get == 1"
                          :disabled="row.get == undefined"
                          class="disabled:bg-gray-300"
                          @click="setPermission(row.module, 'get')"
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          :checked="row.update == 1"
                          :disabled="row.update == undefined"
                          class="disabled:bg-gray-300"
                          @click="setPermission(row.module, 'update')"
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          :checked="row.delete == 1"
                          :disabled="row.delete == undefined"
                          class="disabled:bg-gray-300"
                          @click="setPermission(row.module, 'delete')"
                        />
                      </td>
                    </tr>
                  </template>
                </datatable>
              </div>
              <div class="flex justify-end">
                <button
                  type="submit"
                  class="text-white text-tiny sm:text-base bg-red-600 hover:bg-gray-400 font-bold px-3 rounded-sm inline-flex items-center mr-2 h-10"
                  @click="createRolePermission"
                >
                  Create Role
                </button>
              </div>
              <!-- End Main -->
            </div>
            <!-- /End replace -->
          </div>
        </div>
      </main>
      <home-footer />
    </div>
  </div>
</template>

<script>
import { HomeHeader } from "../../layouts";
import { HomeFooter } from "../../components";
import api from "@/api/apiServices";
import { permissionsModuleName } from "../../utils/permission.js";

export default {
  name: "AddPermission",
  components: {
    HomeFooter,
    HomeHeader,
  },
  data() {
    return {
      columns: [],
      role: null,
      rows: [
        { add: 0, get: 0, update: 0, delete: 0, module: "Articles" },
        { add: 0, get: 0, update: 0, delete: 0, module: "Charities" },
        { add: 0, get: 0, update: 0, delete: 0, module: "Galleries" },
        { add: 0, get: 0, update: 0, delete: 0, module: "Members" },
        { add: 0, module: "MembersExcel" },
        { add: 0, get: 0, update: 0, delete: 0, module: "Permissions" },
        { add: 0, get: 0, update: 0, delete: 0, module: "Presentations" },
        { add: 0, get: 0, update: 0, delete: 0, module: "Summons" },
      ],
      roleName: "",
      add_flag: 0,
      get_flag: 0,
      update_flag: 0,
      delete_flag: 0,
    };
  },
  methods: {
    getModuleName(moduleName) {
      if (permissionsModuleName[moduleName]) {
        return permissionsModuleName[moduleName];
      } else {
        return moduleName;
      }
    },
    async setPermission(module, type) {
      for (let i = 0; i < this.rows.length; i++) {
        let element = this.rows[i];
        if (element.module == module) {
          element[type] = element[type] == 1 ? 0 : 1;
        }
      }
      this[type + "_flag"] = this.getAllPermissionTypeStatus(type);
    },
    getAllPermissionTypeStatus(type) {
      var flag = 1;
      for (let i = 0; i < this.rows.length; i++) {
        let element = this.rows[i];
        if (element[type] == 0) {
          flag = 0;
          break;
        }
      }
      return flag;
    },
    async setPermissionAll(type) {
      let flag = this[type + "_flag"] == 0 ? 1 : 0;
      for (let i = 0; i < this.rows.length; i++) {
        let element = this.rows[i];
        if (element[type] != undefined) element[type] = flag;
      }
      this[type + "_flag"] = flag;
    },
    async createRolePermission() {
      if (this.roleName.trim() == "") {
        this.$fire({
          title: "Please enter the role name",
          type: "error",
        });
      } else {
        let params = {
          roleName: this.roleName.trim(),
          permissions: this.rows,
        };
        api
          .addRolePermission(params)
          .then((res) => {
            this.$fire({
              title: "Permissions added",
              text: res.message,
              type: "success",
              timer: 3000,
            });
            this.$router.back();
          })
          .catch((error) => {
            this.$fire({
              title: "Failed to add permissions",
              text: error.message,
              type: "error",
            });
          });
      }
    },
  },
};
</script>
<style lang="css">
th {
  background-color: #3a4750;
  color: white;
  border: 1px solid;
  border-color: white;
}
</style>
<style src="../../styles/table.css"></style>
